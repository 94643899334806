/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/daisyui@5.0.18/base/properties.css
 * - /npm/daisyui@5.0.18/base/reset.css
 * - /npm/daisyui@5.0.18/base/rootcolor.css
 * - /npm/daisyui@5.0.18/base/rootscrollgutter.css
 * - /npm/daisyui@5.0.18/base/rootscrolllock.css
 * - /npm/daisyui@5.0.18/base/scrollbar.css
 * - /npm/daisyui@5.0.18/base/svg.css
 * - /npm/daisyui@5.0.18/components/button.css
 * - /npm/daisyui@5.0.18/components/menu.css
 * - /npm/daisyui@5.0.18/theme/light.css
 * - /npm/daisyui@5.0.18/components/modal.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
